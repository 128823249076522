<template>
  <div class="posts">
    <h1>Digital Document Management</h1>
    <h3>Fill out the form below to register:</h3>
    <form v-on:submit="registerUser">
      <input type="text" v-model="registerData.emailAddress" placeholder="Enter Email address">
      <br><br>
      <input type="text" v-model="registerData.firstName" placeholder="Enter First name"> &nbsp;
      <input style="margin-left=10px" type="text" v-model="registerData.lastName" placeholder="Enter Last name">
      <br><br>
      <input type="password" v-model="registerData.password1" placeholder="Enter Password">
      <br><br>
      <input type="password" v-model="registerData.password2" placeholder="Confirm Password">
      <br><br>
      <input type="password" v-model="registerData.authCode" placeholder="Alpha Authorization Code">
      <br><br>
      <input type="submit" value="Register">
      <br><br>
      <span>Welcome to the Trusted Boat alpha test program.</span>
      <br>
      <span>Please contact Trusted Boat support to receive an Authorization Code.</span>
      <br>
      <span>support@TrustedBoat.com</span>
    </form>
    <br><br>
    <span v-if="registerReponse.data">
      <b>{{ registerReponse.data }}</b>
    </span>
    <br>
    <vue-instant-loading-spinner id='loader' ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>

import PostService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";

export default {
  name: "response",
  props: ["emailaddress", "apiresponse", "tableheading", "reroute"],
  data() {
    return {
      registerData: {},
      registerReponse: {
        data: ""
      }
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {
    
    async registerUser() {
      this.registerReponse.data = null;
      await this.runSpinner();
      if (!this.registerData.emailAddress ||
          !this.registerData.firstName || 
          !this.registerData.lastName || 
          !this.registerData.password1 || 
          !this.registerData.password2 || 
          !this.registerData.authCode) {
        let response = 'Please fill out all of the entry fields.';
        this.registerReponse.data = response;
        await this.hideSpinner();
      } else if (this.registerData.password1 != this.registerData.password2) {
        let response = "Passwords don't match. Please correct the password and password confirmation.";
        this.registerReponse.data = response;
        await this.hideSpinner();
      } else {
        const apiResponse = await PostService.registerUser(
          this.registerData.emailAddress,
          this.registerData.firstName,
          this.registerData.lastName,
          this.registerData.password1,
          this.registerData.password2,
          this.registerData.authCode
        );

        if (apiResponse.data.err) {
          this.registerReponse.data = apiResponse.data.err;
          await this.hideSpinner();
        } else {
          this.$router.push({ name: 'Login', params: {}});
        }
      }
    },

    async runSpinner() {
      this.$refs.Spinner.show();
    },

    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  }
};
</script>