<template>
  <div class="posts">
    <h1>Digital Document Management</h1>
    <h3>Lets put all kinds of cool sales info here!</h3>
    <h3>If you are a returning customer please login here</h3>
    <input class="" type="Button" value="Login" v-on:click="login()">
    <br>
    
    <br>
    <h3>If you are new to our site, register here!</h3>
    <input class="" type="Button" value="Register" v-on:click="register()">
    <br><br>
    <h3 style="width: 50%; margin: auto">
      If you did not buy a report via your YB/BD and wish to buy one, contact support@trustedboat.com
    </h3>
    <vue-instant-loading-spinner id='loader' ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";

export default {
  name: "response",
  props: ["emailaddress", "apiresponse", "tableheading", "reroute"],
  data() {
    return {
      loginData: {},
      registerData: {},
      registerReponse: {
        data: ""
      },
      loginReponse: {
        data: ""
      }
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {

    async register() {
      this.$router.push({ name: 'Register', params: {}});
    },
    async login() {
      this.$router.push({ name: 'Login', params: {}});
    },
    async registerUser() {
      this.loginReponse = null;
      await this.runSpinner();
      const apiResponse = await PostsService.registerUser(
        this.registerData.emailAddress,
        this.registerData.firstName,
        this.registerData.lastName,
        this.registerData.password1,
        this.registerData.password2
      );

      this.registerReponse = apiResponse;
      await this.hideSpinner();
    },
    
    async validateUser() {
      this.registerReponse = null;
      await this.runSpinner();
      if (!this.loginData.emailAddress) {
        let response = 'Please enter an Email Address';
        this.loginReponse.data = response;
        await this.hideSpinner();
      } else if (!this.loginData.password) {
        let response = 'Please enter a Password';
        this.loginReponse.data = response;
        await this.hideSpinner();
       } else {
        const apiResponse = await PostsService.validateUser(
          this.loginData.emailAddress
        );
        if (apiResponse.data.err) {
          this.loginReponse.data = apiResponse.data.err;
        } 
        else if (this.$route.params.reroute){
          this.$router.push({ name: this.$route.params.reroute, params: { emailaddress: this.loginData.emailAddress}});
        }else {
          if (this.loginData.emailAddress == 'app-admin') {
            const apiResponse = await PostsService.queryAllDigitalAssets(this.loginData.emailAddress);
            this.$router.push({ name: 'AdminDigitalAssets', params: { emailaddress: this.loginData.emailAddress, apiresponse: apiResponse.data, tableheading: "Documents"}});
          } else {
            const apiResponse = await PostsService.queryDigitalAssetsByUser(this.loginData.emailAddress);
            this.$router.push({ name: 'ShowDigitalAssets', params: { emailaddress: this.loginData.emailAddress, apiresponse: apiResponse.data, tableheading: "My Boats"}});
          }
        }
        await this.hideSpinner();
      }
    },

    async runSpinner() {
      this.$refs.Spinner.show();
    },

    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  }
};
</script>