<template>

  <div class="posts">
    <button v-on:click="showUsersBoats()">Return to Boats</button> &nbsp;
    <button v-on:click="createNewFolder()">New Folder</button> &nbsp;
    <h1>{{$route.params.tableheading}}</h1>
    <div id="table">
      <table id="folderTable" v-if=$route.params.apiresponse>
        <thead>
          <tr>
            <th></th>
            <th>Folder Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="folderEntry.id" v-for="folderEntry in $route.params.apiresponse">
            <td><input type="radio" id="folders" v-model="picked" v-on:change="clearError" name="picked" :value=folderEntry.name>
            <label for="assets"></label></td>
            <td>{{folderEntry.name}}</td>
            <td>{{folderEntry.description}}</td>
          </tr>
        </tbody>
      </table>
      <br>
      <span v-if=picked>You have selected <b>{{picked}}</b><br><br></span>
      <span v-if=errorMsg>{{errorMsg}}<br><br></span>
    </div>
    <br>
    <form v-show="showAddFolder" v-on:submit="saveNewFolder()">
      <input type="text" v-model="newFolderName" placeholder="Enter name for new folder."> &nbsp;
      <input type="text" v-model="newFolderDescription" placeholder="Enter description.">
      <br><br>
      <input type="submit" value="Save New Folder"/>
      <br>
    </form>
    <br>
    <br>
    <button v-on:click="showFoldersAssets()">View Documents in Folder</button> &nbsp;
    <v-dialog/>
    <vue-instant-loading-spinner id="loader" ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>
var dateFormat = require('dateformat');
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
export default {
  name: "response",
  props: ["emailaddress", "selectedasset", "assetrecord"],
  data() {
    return {
      input: {},
      picked: null,
      response: null,
      errorMsg: null,
      showAddFolder: false,
      newFolderName: null,
      newFolderDescription: null,
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {
    prettyPrintDate(timestamp){
      if(timestamp)
        return dateFormat(new Date(timestamp), "mmm d, yyyy, h:MM:ss TT");
      else
        return null;
    },

    findElement(array, key) {
      for (var i=0; i < array.length; i++)
        if (array[i].name === key)
          return array[i];

      // will return undefined if not found; you could return a default instead
    },
    clearError(){
      this.errorMsg = null;
    },
    async showUsersBoats() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      const apiResponse = await PostsService.queryBoatsForUser(this.$route.params.emailaddress);
      this.$router.push({ name: 'ShowUsersBoats', params: { emailaddress: this.$route.params.emailaddress, apiresponse: apiResponse.data, tableheading: `My Boats`}});
      this.hideSpinner();
    },
    async createNewFolder() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.newFolderName = "";
      this.newFolderDescription = "";
      this.showAddFolder = !this.showAddFolder;
    },
    async saveNewFolder() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      await this.runSpinner();
      let element = this.findElement(this.$route.params.apiresponse, this.newFolderName);
      if (element) {
        this.errorMsg ="Folder names need to be different."
      } else {
        const apiResponse = await PostsService.saveFolderForUserBoat(
          this.$route.params.emailaddress, 
          this.$route.params.hullId, 
          this.newFolderName, 
          this.newFolderDescription);
        this.response = apiResponse.data;
        this.$route.params.apiresponse = apiResponse.data;
        this.showAddFolder = false;
      }
      this.$forceUpdate();
      await this.hideSpinner();

    },
    async showFoldersAssets() {
      await this.runSpinner();
      this.response = null; 
      //error checking for making sure a file has been selected.
      if (this.picked === null ) {
        console.error('Error - no folder is selected.')
        this.errorMsg = "Please select a folder!";
        this.$forceUpdate();
        await this.hideSpinner();
      
      } else if (this.$route.params.emailaddress === undefined) {
        this.errorMsg = 'Error - no emailAddress found.';
        console.error(this.errorMsg);
        this.$router.push({ name: 'Home'});
        this.$forceUpdate();
        await this.hideSpinner();

      }
      else {
        this.errorMsg = null;
        const apiResponse = await PostsService.queryAssetsForUserBoatFolder(this.$route.params.emailaddress, this.$route.params.hullId, this.picked);
        this.$router.push({ name: 'ShowFoldersAssets', 
        params: { emailaddress: this.$route.params.emailaddress, 
                  hullId: this.$route.params.hullId, 
                  boatName: this.$route.params.boatName, 
                  folderName: this.picked, 
                  apiresponse: apiResponse.data, 
                  tableheading: `${this.$route.params.boatName} - ${this.picked} - Documents`}});
      }
    },
    async runSpinner() {
      this.$refs.Spinner.show();
    },
    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  },
  mounted: async function() {
    //if we reached here before logging in, redirect the user to login
    if(!this.$route.params.emailaddress){
      this.$router.push({ name: 'Home'});
    }
  }
};
</script>

<style>
#folderTable {
  font-family: 'Open Sans', sans-serif;
  width: 80%;
  border-collapse: collapse;
  border: 3px solid #44475C;
  margin: 0 auto;
}

#folderTable th {
  text-transform: uppercase;
  text-align: left;
  background: #44475C;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

#folderTable td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7D82A8;
}
#folderTable td:last-child {
  border-right: none;
}

#folderTable tbody tr:nth-child(2n) td {
  background: #D4D8F9;
}

#folderTable tbody tr:nth-child(2n+1) td {
  background: #FFFFFF;
}
</style>