<template>
  <div class="posts">
    <h1>Upload a Document</h1>
    <h2>{{$route.params.boatName}} - {{$route.params.folderName}}</h2>
    <form v-on:submit="uploadDigitalAsset">
      <label for="digitalAsset">Choose the file to upload: &nbsp;</label>
      <input type="file" @change="onFileChange">
      <br><br>
      <input type="submit" value="Upload Digital Document"/>
    </form>
    <br><br>
    <span v-if="uploadAssetResponse">
      <b>{{ uploadAssetResponse.data }}</b>
    </span>
    <br><br>
    <button v-on:click="showFoldersAssets()">Back</button> &nbsp;
    <br>
    <span>(Return to the "Documents in a specific folder" view)</span>
    <br>
    <vue-instant-loading-spinner id="loader" ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>

<script>
var fs = require("fs");
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
export default {
  name: "response",
  props: ["emailaddress"],
  data() {
    return {
      input: {},
      uploadAssetData: {
        digitalAssetFile: null,
        digitalAssetFileName: null,
        digitalAssetFileBuffer: null
      },
      uploadAssetResponse: {
        data: ""
      },
      assetDataURL: null,
      response: null
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.uploadAssetData.digitalAssetFile = files[0];
      this.uploadAssetResponse.data = "";
    },

    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      })
    },

    async uploadDigitalAsset() {
      this.runSpinner();
      if(this.uploadAssetData.digitalAssetFile){
        this.uploadAssetData.digitalAssetFileName = this.uploadAssetData.digitalAssetFile.name;
        this.uploadAssetData.digitalAssetFileBuffer = await this.readFileAsync(this.uploadAssetData.digitalAssetFile);
        const apiResponse = await PostsService.saveAssetForUserBoatFolder(
                            this.$route.params.emailaddress, this.$route.params.hullId, 
                            this.$route.params.folderName, this.uploadAssetData.digitalAssetFileName, 
                            this.uploadAssetData.digitalAssetFile.type, this.uploadAssetData.digitalAssetFileBuffer);
        if(apiResponse.data.err){
          this.uploadAssetResponse.data = apiResponse.data.err;
        }
        else{
          this.uploadAssetResponse.data = apiResponse.data;
        }
      }
      else{
        this.uploadAssetResponse.data = "Please choose a file and then click \"Upload Digital Document\".";
      }
      this.hideSpinner();
    },

    async showFoldersAssets() {
      this.response = null; 
      this.errorMsg = null;
      const apiResponse = await PostsService.queryAssetsForUserBoatFolder(this.$route.params.emailaddress, this.$route.params.hullId, this.$route.params.folderName);
      this.$router.push({ name: 'ShowFoldersAssets', 
        params: { 
          emailaddress: this.$route.params.emailaddress, 
          hullId: this.$route.params.hullId, 
          boatName: this.$route.params.boatName, 
          folderName: this.$route.params.folderName, 
          apiresponse: apiResponse.data, 
          tableheading: `${this.$route.params.boatName} - ${this.$route.params.folderName} - Documents`
        }
      });
    },
    async runSpinner() {
      this.$refs.Spinner.show();
    },
    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  },
  mounted: async function() {
    //if we reached here before logging in, redirect the user to login
    if(!this.$route.params.emailaddress){
      this.$router.push({ name: 'Home', params: {reroute: 'UploadAsset'}});
    }
  }
};
</script>
