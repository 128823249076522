<template>
  <div id="app">
    <div style="width: 70%; margin: auto" v-if=$route.params.emailaddress><span>
      If you provide a recovery email, or a cell phone that we can text, TBR will be able to reach you, 
      even if your primary email address fails. please send to support@trustedboat.com
    </span></div>
    <h3 v-if=!$route.params.emailaddress>
      Logging in to Trusted Boat indicates your acceptance of both the 
      <a :href="'/files/TBRPrivacyPolicy.docx'" download>Privacy Policy</a> 
      and the 
      <a :href="'/files/TBRIndividualTermsofService.docx'" download>Terms of Service</a>
    </h3>
    <div class='mbc_title row'>
      <div class='comp_logo'>
        <img style='height: 165px; width: 475px;' alt="Trusted Boat logo" src="./assets/tb_icon.png">
      </div>
      <!-- <div class='comp_name'>
        <div>Trusted Boat&#0153;</div>
      </div> -->
    </div>
    <h4 v-if=$route.params.emailaddress>Logged in as {{$route.params.emailaddress}}.
      <router-link to="/">Logout?</router-link>&nbsp;</h4>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.chart-wrapper {
  width: 100%;
  height: 700px;
}
#loader {
  position:fixed;
  padding-top: 250px;
}
.chart-wrapper {
  position:fixed;
  padding-left:32%;
}
.mbc_title {
  height: 150px;
  color: #1c55ff;
  font: 'montserrat';
  font-size: 36px;
  line-height: 40px;
}
.comp_logo {
  /* text-align: right; */
  flex: 45%;
}
.comp_name {
  flex: 55%;
  text-align: left;
  padding-top: 10px;
}
.row {
  display: flex;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

form { 
  display: inline-block; 
}

input {
  font-size: 1.2em;
}

button {
  font-size: 1em;
}
</style>