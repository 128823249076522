<template>

  <div class="posts">
    <span>Boat Count: {{ $route.params.apiresponse.length }}</span>
    <br>
    <button v-on:click="queryUsersBoats()">Refresh Boats</button> &nbsp;
    <button v-on:click="createNewBoat()">New Boat</button> &nbsp;
    <br> <br>
    <a :href="'/files/TrustedBoatReport-FAQ.docx'" download>Frequently Asked Questions (FAQ)</a> &nbsp;
    <h1>{{$route.params.tableheading}}</h1>
    <div id="table">
      <table id="boatTable" v-if=$route.params.apiresponse>
        <thead>
          <tr>
            <th></th>
            <th>Hull Id (HIN)</th>
            <th>Current Name</th>
            <th>Description</th>
            <th>Length (Ft)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="boatEntry.email" v-for="boatEntry in $route.params.apiresponse">
            <td><input type="radio" id="boats" v-model="picked" v-on:change="clearError" name="picked" :value=boatEntry.hullId>
            <label for="boats"></label></td>
            <td>{{boatEntry.hullId}}</td>
            <td>{{boatEntry.name}}</td>
            <td>{{boatEntry.description}}</td>
            <td>{{boatEntry.length}}</td>
          </tr>
        </tbody>
      </table>
      <br>
      <span v-if=picked>You have selected <b>{{picked}}</b><br><br></span>
      <span style="font-size:large; color:red;" v-if=errorMsg>{{errorMsg}}<br><br></span>
    </div>
    <br>
    <form v-show="showAddBoat" v-on:submit="saveNewBoat()" style="padding-right: 10px;">
      <input type="text" v-model="newHullId" placeholder="Enter HIN for new boat" ref="newHullId"> &nbsp;
      <input type="text" v-model="newBoatName" placeholder="Enter name for new boat"> &nbsp;
      <input type="text" v-model="newBoatDescription" placeholder="Enter description"> &nbsp;
      <input type="text" v-model="newBoatLength" placeholder="Enter length in feet">
      <br><br>
      <input type="submit" value="Save New Boat"/>
    </form>
    <form v-show="showChangeBoat" v-on:submit="saveChangeBoat()" style="padding-right: 10px;">
      <input type="text" v-model="newChangeBoatName" placeholder="Enter name for new boat" ref="newChangeBoatName"> &nbsp;
      <input type="text" v-model="newChangeBoatDescription" placeholder="Enter description"> &nbsp;
      <input type="text" v-model="newChangeBoatLength" placeholder="Enter length">
      <br><br>
      <input type="submit" value="Save New Boat Information"/>
    </form>
    <br><br>
    <button v-on:click="showBoatsFolders()">View Folders for Boat</button> &nbsp;
    <button v-on:click="changeOwnershipOfBoat()">Change Ownership of Boat</button> &nbsp;
    <button v-on:click="changeBoatName()">Change Information of Boat</button> &nbsp;
    <br><br>
    <span style="width: 40%;">(During development we will implement the ability to give another person view only access to a boat.)</span>
    <v-dialog/>
    <vue-instant-loading-spinner id="loader" ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>
var dateFormat = require('dateformat');
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
export default {
  name: "response",
  props: ["emailaddress", "selectedboat", "boatrecord"],
  data() {
    return {
      input: {},
      picked: null,
      response: null,
      errorMsg: null,
      showAddBoat: false,
      showChangeBoat: false,
      newHullId: null,
      newBoatName: null,
      newBoatDescription: null,
      newBoatLength: null,
      newChangeBoatName: null,
      newChangeBoatDescription: null,
      newChangeBoatLength: null,
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {
    prettyPrintDate(timestamp){
      if(timestamp)
        return dateFormat(new Date(timestamp), "mmm d, yyyy, h:MM:ss TT");
      else
        return null;
    },

    findElement(array, key) {
      for (var i=0; i < array.length; i++)
        if (array[i].hullId === key)
          return array[i];

      // will return undefined if not found; you could return a default instead
    },
    clearError(){
      this.errorMsg = null;
    },
    async queryUsersBoats() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      const apiResponse = await PostsService.queryBoatsForUser(this.$route.params.emailaddress);
      this.response = apiResponse.data;
      console.log(this.response);
      this.$route.params.apiresponse = apiResponse.data;
      this.$route.params.tableheading = "My Boats";
      this.$forceUpdate();
      this.hideSpinner();
    },
    async createNewBoat() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.newHullId = "";
      this.newBoatName = "";
      this.newBoatDescription = "";
      this.newBoatLength = "";
      this.showChangeBoat = false;
      this.showAddBoat = !this.showAddBoat;
      if (this.showAddBoat) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    },
    checkDuplicateHIN(array, hullId) {
      if (this.findElement(array, hullId) == undefined) {
        return false;
      }
      return true;
    },
    isAlpha (str,start,end) {
      console.log(str.substring(start,end));
      if (str.length !== 1 && str.substring(start,end).match(/^[a-zA-Z]*$/)) {
        return true;
      }
      return false;
    },
    isHINValid( hin ) {
      if ( hin.length == 12 ) {
        if ( this.isAlpha(hin,0,3) ) {
          return true;
        }
      } else if ( hin.length == 14 ) {
        if ( this.isAlpha(hin,0,5) ) {
          return true;
        }
      }

      return false;
    },
    isLengthValid( len ) {
      if (len.length == 0 || Number(len) == NaN) {
        return false;
      }
      return true;
    },
    async saveNewBoat() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      await this.runSpinner();
      // Check for duplicate HIN (just for user for now)
      if (this.checkDuplicateHIN(this.$route.params.apiresponse, this.newHullId)) {
          this.errorMsg = 'Duplicate HIN is not allowed. Enter a valid and unique HIN.';
          this.$forceUpdate();
      // Be smart about the HIN
      } else if (!this.isHINValid(this.newHullId)) {
        this.errorMsg = 'Please enter a valid HIN (12 or 14 characters long)';
        this.$forceUpdate();
      } else if (!this.isLengthValid(this.newBoatLength)) {
        this.errorMsg = 'Please enter a valid Length (Whole feet - Truncate to full foot)';
        this.$forceUpdate();
      } else {
        const apiResponse = await PostsService.saveBoatForUser(this.$route.params.emailaddress, this.newHullId, this.newBoatName, this.newBoatDescription, this.newBoatLength);
        console.log(`apiResponse: ${JSON.stringify(apiResponse)}`);
        if (apiResponse.data.err) {
          this.errorMsg = apiResponse.data.err;
        } else {
          this.response = apiResponse.data;
          this.$route.params.apiresponse = apiResponse.data;
          this.showAddBoat = false;
        }
        this.$forceUpdate();
      }
      await this.hideSpinner();
    },
    async changeBoatName() {
      if (this.picked === null ) {
        this.errorMsg = null;
        this.newChangeBoatName = "";
        this.newChangeBoatDescription = "";
        this.newChangeBoatLength = "";
        this.showAddBoat = false;
        this.showChangeBoat = false;

        console.error('Error - no boat is selected.')
        this.errorMsg = "Please select a boat!";
        this.$forceUpdate();
        await this.hideSpinner();
      } else {
        this.errorMsg = null;
        this.newChangeBoatName = "";
        this.newChangeBoatDescription = "";
        this.newChangeBoatLength = "";
        this.showAddBoat = false;
        this.showChangeBoat = !this.showChangeBoat;
        if (this.showChangeBoat) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    },
    async saveChangeBoat() {
      this.errorMsg = null;
      this.response = null;
      await this.runSpinner();
      await this.runSpinner();
      this.response = null; 
      //error checking for making sure a file has been selected.
      if (this.picked === null ) {
        console.error('Error - no boat is selected.')
        this.errorMsg = "Please select a boat!";
        this.$forceUpdate();
        await this.hideSpinner();
      } else if (this.newChangeBoatLength.length > 0 && !this.isLengthValid(this.newChangeBoatLength)) {
        this.errorMsg = 'Please enter a valid Length (Whole feet - Truncate to full foot)';
        this.$forceUpdate();
      } else {
        const apiResponse = await PostsService.saveChangeBoatForUser(this.$route.params.emailaddress, this.picked, this.newChangeBoatName, this.newChangeBoatDescription, this.newChangeBoatLength);
        console.log(`apiResponse: ${JSON.stringify(apiResponse)}`);
        if (apiResponse.data.err) {
          this.errorMsg = apiResponse.data.err;
        } else {
          this.response = apiResponse.data;
          this.$route.params.apiresponse = apiResponse.data;
          this.showChangeBoat = false;
        }
        this.$forceUpdate();
      }
      await this.hideSpinner();
    },
    async showBoatsFolders() {
      await this.runSpinner();
      this.response = null; 
      //error checking for making sure a file has been selected.
      if (this.picked === null ) {
        console.error('Error - no boat is selected.')
        this.errorMsg = "Please select a boat!";
        this.$forceUpdate();
        await this.hideSpinner();
      
      } else if (this.$route.params.emailaddress === undefined) {
        this.errorMsg = 'Error - no emailAddress found.';
        console.error(this.errorMsg);
        this.$router.push({ name: 'Home'});
        this.$forceUpdate();
        await this.hideSpinner();

      }
      else {
        this.errorMsg = null;
        let selected = this.findElement(this.$route.params.apiresponse, this.picked);
        const apiResponse = await PostsService.queryFoldersForUserBoat(this.$route.params.emailaddress, this.picked);
        this.$router.push({ name: 'ShowBoatsFolders', 
          params: { 
            emailaddress: this.$route.params.emailaddress, 
            hullId: this.picked, 
            boatName: selected.name, apiresponse: 
            apiResponse.data, 
            tableheading: `${selected.name} - Folders`
          }
        });
      }
    },
    async changeOwnershipOfBoat() {
      await this.runSpinner();
      this.response = null; 
      //error checking for making sure a file has been selected.
      if (this.picked === null ) {
        console.error('Error - no boat was selected.')
        this.errorMsg = "Please select a boat!";
        this.$forceUpdate();
        await this.hideSpinner();
      //error checking for making sure a user has logged in.
      } else if (this.$route.params.emailaddress === undefined) {
        this.errorMsg = 'Error - no emailAddress found. Please login first.';
        console.error(this.errorMsg);
        this.$router.push({ name: 'Home'});
        this.$forceUpdate();
        await this.hideSpinner();
      }
      //all good - move over to changeOwnership screen.
      else {
        let entry = this.findElement(this.$route.params.apiresponse, this.picked);
        if (entry != undefined) {
          this.$router.push({ name: 'ChangeOwnershipOfBoat', params: { emailaddress: this.$route.params.emailaddress, selectedasset: this.picked, assetrecord: entry}});
        }
        this.$forceUpdate();
        await this.hideSpinner();
      }
    },
    async runSpinner() {
      this.$refs.Spinner.show();
    },
    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  },
  mounted: async function() {
    //if we reached here before logging in, redirect the user to login
    if(!this.$route.params.emailaddress){
      this.$router.push({ name: 'Home'});
    }
  }
};
</script>

<style>
#boatTable {
  font-family: 'Open Sans', sans-serif;
  width: 80%;
  border-collapse: collapse;
  border: 3px solid #44475C;
  margin: 0 auto;
}

#boatTable th {
  text-transform: uppercase;
  text-align: left;
  background: #44475C;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

#boatTable td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7D82A8;
}
#boatTable td:last-child {
  border-right: none;
}

#boatTable tbody tr:nth-child(2n) td {
  background: #D4D8F9;
}

#boatTable tbody tr:nth-child(2n+1) td {
  background: #FFFFFF;
}
</style>