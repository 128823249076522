<template>
  <div class="posts">
    <h1>Validate a file against a document</h1>
    <h1>{{$route.params.boatName}} - {{$route.params.folderName}} - {{$route.params.assetName}}</h1>
    <div id="table" v-if="$route.params.assetId">
      <form v-on:submit="validateAsset">
        <label for="digitalAsset">Choose the file to validate:</label>
        <input type="file" @change="onFileChange">
        <br><br>
        <span v-if="validateAssetResponse">
          <b>{{ validateAssetResponse }}</b>
        </span>
        <br>
        <span v-if="otsValidateAssetResponse">
          <b>{{ otsValidateAssetResponse }}</b>
        </span>
        <br>
        <input type="submit" value="Validate File"/>
      </form>
      <br>
      <button v-on:click="showFoldersAssets()">Back</button> &nbsp;
      <br>
      <span>(Return to the "Documents in a specific folder" view)</span>
      <br>
    </div>
    <span v-else>
      Page was called incorrectly. Please access through the Trusted Boat menus.
    </span>
    <vue-instant-loading-spinner id="loader" ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
export default {
  name: "response",
  data() {
    return {
      response: null,
      validateAssetResponse: null,
      otsValidateAssetResponse: null,
      validateAssetData: {
        digitalAssetFile: null,
        digitalAssetFileName: null,
        digitalAssetFileBuffer: null
      },
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  mounted: async function() {
    //if we reached here before logging in, redirect the user to login
    if(!this.$route.params.emailaddress){
      this.$router.push({ name: 'Home'});
    }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.validateAssetData.digitalAssetFile = files[0];
      this.validateAssetResponse = "";
      this.otsValidateAssetResponse = "";
    },

    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      })
    },
    async showFoldersAssets() {
      this.response = null; 
      this.errorMsg = null;
      const apiResponse = await PostsService.queryAssetsForUserBoatFolder(this.$route.params.emailaddress, this.$route.params.hullId, this.$route.params.folderName);
      this.$router.push({ name: 'ShowFoldersAssets', 
        params: { 
          emailaddress: this.$route.params.emailaddress, 
          hullId: this.$route.params.hullId, 
          boatName: this.$route.params.boatName, 
          folderName: this.$route.params.folderName, 
          apiresponse: apiResponse.data, 
          tableheading: `${this.$route.params.boatName} - ${this.$route.params.folderName} - Documents`
        }
      });
    },
    async validateAsset() {
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      if(this.validateAssetData.digitalAssetFile){
        this.validateAssetData.digitalAssetFileName = this.validateAssetData.digitalAssetFile.name;
        this.validateAssetData.digitalAssetFileBuffer = await this.readFileAsync(this.validateAssetData.digitalAssetFile);

        const apiResponse = await PostsService.validateAsset(
            this.$route.params.emailaddress,
            this.$route.params.assetId, this.validateAssetData.digitalAssetFileName, 
            this.validateAssetData.digitalAssetFile.type, this.validateAssetData.digitalAssetFileBuffer
        );
        if (apiResponse.data.err) {
          this.validateAssetResponse = apiResponse.data.err;
        } else {
          this.validateAssetResponse = apiResponse.data.mbcValidation;
          this.otsValidateAssetResponse = apiResponse.data.otsValidation;
        }
        console.log(`apiResponse: ${JSON.stringify(apiResponse)}`);
      }
      else{
        this.validateAssetResponse = "Please choose a file and then click \"Validate File\".";
      }
      this.$forceUpdate();
      this.hideSpinner();
    },
    async runSpinner() {
      this.$refs.Spinner.show();
    },
    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  }
};
</script>