<template>

  <div class="posts">
    <span>Displaying {{ ((this.currentPage - 1) * this.pageSize) + 1 }} to {{ Math.min(((this.currentPage - 1) * this.pageSize) + this.pageSize, $route.params.totalBoats) }} of {{ $route.params.totalBoats }}</span>
    <br>
    <button v-show="showFirstPrevious" v-on:click="queryAllFirstPage()">First Page</button> &nbsp;
    <button v-show="showFirstPrevious" v-on:click="queryAllPreviousPage()">Previous Page</button> &nbsp;
    <button v-show="showNextLast" v-on:click="queryAllNextPage()">Next Page</button> &nbsp;
    <button v-show="showNextLast" v-on:click="queryAllLastPage()">Last Page</button> &nbsp;
    <br>
    <button v-on:click="displayAllBoats()">Display All Boats</button> &nbsp;
    <h1>{{$route.params.tableheading}}</h1>
    <div id="table">
      <table id="assetTable" v-if=$route.params.apiresponse>
        <thead>
          <tr>
            <th>Original Owner</th>
            <th>New Owner</th>
            <th>Hull Number</th>
            <th>Boat Name</th>
            <th>Description</th>
            <th>Length</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="assetEntry.hullId" v-for="assetEntry in $route.params.apiresponse">
            <td>{{assetEntry.origOwner}}</td>
            <td>{{assetEntry.newOwner}}</td>
            <td>{{assetEntry.hullId}}</td>
            <td>{{assetEntry.boatName}}</td>
            <td>{{assetEntry.boatDescription}}</td>
            <td>{{assetEntry.boatLength}}</td>
            <td>{{assetEntry.transferDate}}</td>
          </tr>
        </tbody>
      </table>
      <br>
    </div>
    <v-dialog/>
    <vue-instant-loading-spinner id="loader" ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>
var dateFormat = require('dateformat');
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
export default {
  name: "response",
  props: ["emailaddress", "selectedasset", "assetrecord"],
  data() {
    return {
      input: {},
      picked: null,
      response: null,
      errorMsg: null,
      showNextLast: true,
      showFirstPrevious: null,
      currentPage: 1,
      pageSize: 50,
      sort: 0
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {
    prettyPrintDate(timestamp){
      if(timestamp)
        return dateFormat(new Date(timestamp), "mmm d, yyyy, h:MM:ss TT");
      else
        return null;
    },

    findElement(array, key) {
      for (var i=0; i < array.length; i++)
        if (array[i].Key === key)
          return array[i];

      // will return undefined if not found; you could return a default instead
    },
    clearError(){
      this.errorMsg = null;
    },
    async showCorrectPageButtons() {
      this.showNextLast = true;
      if (this.currentPage == Math.floor(Math.floor(this.$route.params.totalBoats / this.pageSize) + 1)) { 
        this.showNextLast = null;
      }
      this.showFirstPrevious = true;
      if (this.currentPage == 1) { 
        this.showFirstPrevious = null;
      }
    },
    async queryAllAssetsSortHID() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      this.sort = 1;
      this.currentPage = 1;
      const apiResponse = await PostsService.queryAllBoatsPaged(this.$route.params.emailaddress, this.currentPage, this.pageSize, this.sort);
      this.response = apiResponse.data.rows;
      this.$route.params.totalBoats = apiResponse.data.totalBoats;
      this.$route.params.apiresponse = apiResponse.data.rows;
      this.$route.params.tableheading = "All Boats in the System";
      this.showCorrectPageButtons();
      this.$forceUpdate();
      this.hideSpinner();
    },
    async queryAllAssetsSortName() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      this.sort = 2;
      this.currentPage = 1;
      const apiResponse = await PostsService.queryAllBoatsPaged(this.$route.params.emailaddress, this.currentPage, this.pageSize, this.sort);
      this.response = apiResponse.data.rows;
      this.$route.params.totalBoats = apiResponse.data.totalBoats;
      this.$route.params.apiresponse = apiResponse.data.rows;
      this.$route.params.tableheading = "All Boats in the System";
      this.showCorrectPageButtons();
      this.$forceUpdate();
      this.hideSpinner();
    },
    async queryAllFirstPage() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      this.currentPage = 1;
      const apiResponse = await PostsService.queryAllBoatsPaged(this.$route.params.emailaddress, this.currentPage, this.pageSize, this.sort);
      this.response = apiResponse.data.rows;
      this.$route.params.totalBoats = apiResponse.data.totalBoats;
      this.$route.params.apiresponse = apiResponse.data.rows;
      this.$route.params.tableheading = "All Boats in the System";
      this.showCorrectPageButtons();
      this.$forceUpdate();
      this.hideSpinner();
    },
    async queryAllPreviousPage() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      this.currentPage -= 1;
      const apiResponse = await PostsService.queryAllBoatsPaged(this.$route.params.emailaddress, this.currentPage, this.pageSize, this.sort);
      this.response = apiResponse.data.rows;
      this.$route.params.totalBoats = apiResponse.data.totalBoats;
      this.$route.params.apiresponse = apiResponse.data.rows;
      this.$route.params.tableheading = "All Boats in the System";
      this.showCorrectPageButtons();
      this.$forceUpdate();
      this.hideSpinner();
    },
    async queryAllNextPage() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      this.currentPage += 1;
      const apiResponse = await PostsService.queryAllBoatsPaged(this.$route.params.emailaddress, this.currentPage, this.pageSize, this.sort);
      this.response = apiResponse.data.rows;
      this.$route.params.totalBoats = apiResponse.data.totalBoats;
      this.$route.params.apiresponse = apiResponse.data.rows;
      this.$route.params.tableheading = "All Boats in the System";
      this.showCorrectPageButtons();
      this.$forceUpdate();
      this.hideSpinner();
    },
    async queryAllLastPage() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      this.currentPage = Math.floor(this.$route.params.totalBoats / this.pageSize) + 1;
      const apiResponse = await PostsService.queryAllBoatsPaged(this.$route.params.emailaddress, this.currentPage, this.pageSize, this.sort);
      this.response = apiResponse.data.rows;
      this.$route.params.totalBoats = apiResponse.data.totalBoats;
      this.$route.params.apiresponse = apiResponse.data.rows;
      this.$route.params.tableheading = "All Boats in the System";
      this.showCorrectPageButtons();
      this.$forceUpdate();
      this.hideSpinner();
    },
    async displayAllBoats() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      const apiResponse = await PostsService.queryAllBoatsPaged(this.$route.params.emailaddress, 1, this.pageSize, 0);
      this.$router.push({ name: 'AdminDigitalAssets', params: { emailaddress: this.$route.params.emailaddress, apiresponse: apiResponse.data.rows, totalBoats: apiResponse.data.totalBoats, tableheading: "All Boats in the System"}});
      this.hideSpinner();
    },
    async runSpinner() {
      this.$refs.Spinner.show();
    },
    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  },
  mounted: async function() {
    //if we reached here before logging in, redirect the user to login
    if(!this.$route.params.emailaddress){
      this.$router.push({ name: 'Home'});
    }
  }
};
</script>

<style>
#assetTable {
  font-family: 'Open Sans', sans-serif;
  width: 80%;
  border-collapse: collapse;
  border: 3px solid #44475C;
  margin: 0 auto;
}

#assetTable th {
  text-transform: uppercase;
  text-align: left;
  background: #44475C;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

#assetTable td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7D82A8;
}
#assetTable td:last-child {
  border-right: none;
}

#assetTable tbody tr:nth-child(2n) td {
  background: #D4D8F9;
}

#assetTable tbody tr:nth-child(2n+1) td {
  background: #FFFFFF;
}
</style>