<template>
  <div class="posts">
    <h1>Change Ownership of Boat</h1>
    <h1>{{$route.params.assetrecord.hullId}} - {{$route.params.assetrecord.name}} - {{$route.params.assetrecord.description}}</h1>
    <div id="table">
      <table id="assetTable" v-if=$route.params.assetrecord>
        <thead>
          <tr>
            <th>Boat HIN</th>
            <th>Boat Name</th>
            <th>Added On</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{$route.params.assetrecord.hullId}}</td>
            <td>{{$route.params.assetrecord.name}}</td>
            <td>{{prettyPrintDate($route.params.assetrecord.createddate)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
    <form v-on:submit="changeOwnershipOfBoat">
      <input type="text" v-model="changeOwnershipData.emailAddress" placeholder="Enter Email address of new owner">
      <br><br>
      <input type="submit" value="Change Owner of Boat"/>
    </form>
    <br>
    <span v-if="changeOwnershipResponse">
      <b>{{ changeOwnershipResponse.data }}</b>
    </span>
    <br>
    <button v-on:click="ShowUsersBoats()">Back</button> &nbsp;
    <br>
    <vue-instant-loading-spinner id="loader" ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>

<script>
var fs = require("fs");
var dateFormat = require('dateformat');
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
export default {
  name: "response",
  props: ["emailaddress"],
  data() {
    return {
      input: {},
      changeOwnershipData: {
        emailAddress: null
      },
      changeOwnershipResponse: {
        data: ""
      },
      response: null
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {
    prettyPrintDate(timestamp){
      if(timestamp)
        return dateFormat(new Date(timestamp), "mmm d, yyyy, h:MM:ss TT");
      else
        return null;
    },

    async ShowUsersBoats() {
      this.picked = null;
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      const apiResponse = await PostsService.queryBoatsForUser(this.$route.params.emailaddress);
      this.$router.push({ name: 'ShowUsersBoats', params: { emailaddress: this.$route.params.emailaddress, apiresponse: apiResponse.data, tableheading: `My Boats`}});
      this.hideSpinner();
    },
    async changeOwnershipOfBoat() {
      console.log("Changing ownership of boat");
      this.response = null;
      this.runSpinner();
      if (this.changeOwnershipData.emailAddress === null || this.changeOwnershipData.emailAddress === ""){
        this.changeOwnershipResponse.data = "Please enter the email address for the new owner.";
      }
      else {
        const apiResponse = await PostsService.changeOwnershipOfBoat(this.$route.params.assetrecord.id, this.$route.params.emailaddress, this.changeOwnershipData.emailAddress);
        if (apiResponse.data.err) {
          this.changeOwnershipResponse.data = apiResponse.data.err;
          this.$forceUpdate();
          this.hideSpinner();
        } else {
          this.ShowUsersBoats();
        }
      }
      this.hideSpinner();
    },
    async runSpinner() {
      this.$refs.Spinner.show();
    },
    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  },
  mounted: async function() {
    //if we reached here before logging in, redirect the user to login
    if(!this.$route.params.emailaddress){
      this.$router.push({ name: 'Home'});
    }
  }
};
</script>
