<template>
  <div class="posts">
    <h1>Digital Document Management</h1>
    <h3>Welcome back, Login below:</h3>
    <form v-on:submit="validateUser">
      <input type="text" v-model="loginData.emailAddress" placeholder="Enter Email address" autofocus>
      <br><br>
      <input type="password" v-model="loginData.password" placeholder="Enter Password">
      <br><br>
      <input type="submit" value="Login">
      <br>
      <br>
      <span v-if="loginReponse">
        <b>{{ loginReponse.data }}</b>
      </span>
      <br>
      <h3>New to our site, register here!</h3>
      <input class="" type="Button" value="Register" v-on:click="register()">
    </form>
    <br>
    <vue-instant-loading-spinner id='loader' ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";

export default {
  name: "response",
  props: ["emailaddress", "apiresponse", "tableheading", "reroute"],
  data() {
    return {
      loginData: {},
      loginReponse: {
        data: ""
      }
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {
    
    async register() {
      this.$router.push({ name: 'Register', params: {}});
    },
    async validateUser() {
      this.registerReponse = null;
      await this.runSpinner();
      if (!this.loginData.emailAddress || !this.loginData.password) {
        let response = 'Please enter an Email Address and Password.';
        this.loginReponse.data = response;
        await this.hideSpinner();
      } else {
        
        const apiResponse = await PostsService.validateUser(
          this.loginData.emailAddress,
          this.loginData.password
        );
        if (apiResponse.data.err) {
          this.loginReponse.data = apiResponse.data.err;
        } 
        else if (this.$route.params.reroute){
          this.$router.push({ name: this.$route.params.reroute, params: { emailaddress: this.loginData.emailAddress}});
        }else {
          console.log(`emailAddress: `+ this.loginData.emailAddress); 
          if (this.loginData.emailAddress == 'app-admin') {
            const apiResponse = await PostsService.queryAllBoatsPaged(this.loginData.emailAddress, 1, 50);
            // console.log(apiResponse.data);
            this.$router.push({ name: 'AdminDigitalAssets', params: { emailaddress: this.loginData.emailAddress, apiresponse: apiResponse.data.rows, totalBoats: apiResponse.data.totalBoats, tableheading: "All Boats in the System"}});
          } else {
            this.$router.push({ name: 'ShowUsersBoats', params: { emailaddress: this.loginData.emailAddress, apiresponse: apiResponse.data, tableheading: "My Boats"}});
          }
        }
        await this.hideSpinner();
      }
    },

    async runSpinner() {
      this.$refs.Spinner.show();
    },

    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  }
};
</script>