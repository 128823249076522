import axios from 'axios';

export default() => {
    let apiHost = process.env.VUE_APP_API_HOST;
    let apiPort = process.env.VUE_APP_API_PORT;

    if (!apiHost){
        apiHost = "proxycloud.org";
    }
    if (!apiPort) {
        apiPort = "443";
    }
    return axios.create({
        baseURL: "https://" + apiHost + ":" + apiPort + "/api"
        //baseURL: "http://" + apiHost + ":" + apiPort + ""
    });
};
