import Api from '@/services/api';


export default {
    registerUser(emailAddress, firstName, lastName, password1, password2, authCode) {
        return Api().post('registerUser', {
            emailAddress: emailAddress,
            firstName: firstName,
            lastName: lastName,
            password1: password1,
            password2: password2,
            authCode: authCode,
        });

    },
    validateUser(emailAddress, password) {
        return Api().post('validateUser', {
            emailAddress: emailAddress,
            password: password
        });
    },
    saveBoatForUser(emailAddress, boatHullId, boatName, boatDescription, boatLength) {
        return Api().post('saveBoatForUser', {
            emailAddress: emailAddress,
            boatHullId: boatHullId,
            boatName: boatName,
            boatDescription: boatDescription,
            boatLength: boatLength
        });
    },
    saveChangeBoatForUser(emailAddress, boatHullId, boatName, boatDescription, boatLength) {
        return Api().post('saveChangeBoatForUser', {
            emailAddress: emailAddress,
            boatHullId: boatHullId,
            boatName: boatName,
            boatDescription: boatDescription,
            boatLength: boatLength
        });
    },
    saveFolderForUserBoat(emailAddress, hullId, folderName, folderDescription) {
        return Api().post('saveFolderForUserBoat', {
            emailAddress: emailAddress,
            hullId: hullId,
            folderName: folderName,
            folderDescription: folderDescription
        });
    },
    saveAssetForUserBoatFolder(emailAddress, hullId, folderName, assetFileName, assetFileType, assetFileBuffer) {
        return Api().post('saveAssetForUserBoatFolder', {
            emailAddress: emailAddress,
            hullId: hullId,
            folderName: folderName,
            assetFileName: assetFileName,
            assetFileType: assetFileType,
            assetFileBuffer: assetFileBuffer
        });
    },
    downloadAssetForUserBoatFolder(emailAddress, hullId, folderName, assetFileName) {
        return Api().post('downloadAssetForUserBoatFolder', {
            emailAddress: emailAddress,
            hullId: hullId,
            folderName: folderName,
            assetFileName: assetFileName,
        });
    },
    downloadOTSFileForAsset(emailAddress, hullId, folderName, assetFileName) {
        return Api().post('downloadAssetForUserBoatFolder', {
            emailAddress: emailAddress,
            hullId: hullId,
            folderName: folderName,
            assetFileName: assetFileName,
        });
    },
    validateAsset(emailAddress, assetId, fileName, fileType, fileBuffer) {
        return Api().post('validateAsset', {
            emailAddress: emailAddress,
            assetId: assetId,
            fileName: fileName,
            fileType: fileType,
            fileBuffer: fileBuffer
        });
    },
    changeOwnershipOfBoat(assetId, assetModifier, newAssetOwner) {
        return Api().post('changeOwnershipOfBoat', {
            assetId: assetId,
            assetModifier: assetModifier,
            newAssetOwner: newAssetOwner
        });
    },
    queryBoatsForUser(emailAddress) {
        return Api().post('queryBoatsForUser', {
            emailAddress: emailAddress,
        });
    },
    queryFoldersForUserBoat(emailAddress, boatName) {
        return Api().post('queryFoldersForUserBoat', {
            emailAddress: emailAddress,
            boatName: boatName,
        });
    },
    queryAssetsForUserBoatFolder(emailAddress, hullId, folderName) {
        return Api().post('queryAssetsForUserBoatFolder', {
            emailAddress: emailAddress,
            hullId: hullId,
            folderName: folderName,
        });
    },
    queryAllDocuments(emailAddress) {
        return Api().post('queryAllAssets', {
            emailAddress: emailAddress,
        });
    },
    queryAllBoats(emailAddress) {
        return Api().post('queryAllBoats', {
            emailAddress: emailAddress,
        });
    },
    queryAllTransfersPaged(emailAddress, page, pageSize, sortOrder) {
        return Api().post('queryAllTranfersPaged', {
            emailAddress: emailAddress,
            page: page,
            pageSize: pageSize,
            sortOrder: sortOrder,
        });
    },
    queryAllDocumentsPaged(emailAddress, page, pageSize, sortOrder) {
        return Api().post('queryAllDocumentsPaged', {
            emailAddress: emailAddress,
            page: page,
            pageSize: pageSize,
            sortOrder: sortOrder,
        });
    },
    queryAllBoatsPaged(emailAddress, page, pageSize, sortOrder) {
        return Api().post('queryAllBoatsPaged', {
            emailAddress: emailAddress,
            page: page,
            pageSize: pageSize,
            sortOrder: sortOrder,
        });
    }

};