import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/components/Home';
import Register from '@/components/Register';
import Login from '@/components/Login';
import ShowUsersBoats from '@/components/ShowUsersBoats';
import ShowBoatsFolders from '@/components/ShowBoatsFolders';
import ShowFoldersAssets from '@/components/ShowFoldersAssets';
import ShowDigitalAssets from '@/components/ShowDigitalAssets';
import ValidateAsset from '@/components/ValidateAsset';
import AdminDigitalAssets from '@/components/AdminDigitalAssets';
import AdminBoatsAndDocuments from '@/components/AdminBoatsAndDocuments';
import AdminBoatTransfers from '@/components/AdminBoatTransfers';
import UploadAsset from '@/components/UploadAsset';
import ViewAssetModificationRequests from '@/components/ViewAssetModificationRequests';
import UpdateAsset from '@/components/UpdateAsset';
import ChangeOwnershipOfAsset from '@/components/ChangeOwnershipOfAsset';
import ChangeOwnershipOfBoat from '@/components/ChangeOwnershipOfBoat';
import GetHistoryForDigitalAsset from '@/components/GetHistoryForDigitalAsset';
import Health from '@/components/Health';
Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            props: true
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            props: true
        },
        {
            path: '/showUsersBoats',
            name: 'ShowUsersBoats',
            component: ShowUsersBoats,
            props: true
        },
        {
            path: '/changeOwnershipOfBoat',
            name: 'ChangeOwnershipOfBoat',
            component: ChangeOwnershipOfBoat,
            props: true
        },
        {
            path: '/showBoatsFolders',
            name: 'ShowBoatsFolders',
            component: ShowBoatsFolders,
            props: true
        },
        {
            path: '/showFoldersAssets',
            name: 'ShowFoldersAssets',
            component: ShowFoldersAssets,
            props: true
        },
        {
            path: '/showDigitalAssets',
            name: 'ShowDigitalAssets',
            component: ShowDigitalAssets,
            props: true
        },
        {
            path: '/validateAsset',
            name: 'ValidateAsset',
            component: ValidateAsset,
            props: true
        },
        {
            path: '/adminDigitalAssets',
            name: 'AdminDigitalAssets',
            component: AdminDigitalAssets,
            props: true
        },
        {
            path: '/adminBoatsAndDocuments',
            name: 'AdminBoatsAndDocuments',
            component: AdminBoatsAndDocuments,
            props: true
        },
        {
            path: '/adminBoatTransfers',
            name: 'AdminBoatTransfers',
            component: AdminBoatTransfers,
            props: true
        },
        {
            path: '/uploadAsset',
            name: 'UploadAsset',
            component: UploadAsset,
            props: true
        },
        {
            path: '/viewAssetModificationRequests',
            name: 'ViewAssetModificationRequests',
            component: ViewAssetModificationRequests,
            props: true
        },
        {
            path: '/updateAsset',
            name: 'UpdateAsset',
            component: UpdateAsset,
            props: true
        },
        {
            path: '/changeOwnershipOfAsset',
            name: 'ChangeOwnershipOfAsset',
            component: ChangeOwnershipOfAsset,
            props: true
        },
        {
            path: '/getHistoryForDigitalAsset',
            name: 'GetHistoryForDigitalAsset',
            component: GetHistoryForDigitalAsset,
            props: true
        },
        {
            path: '/health',
            name: 'Health',
            component: Health
        }
    ]
});