<template>
  <div class="posts">
    <button v-on:click="showBoatsFolders()">Back to Folders</button> &nbsp;
    <button v-on:click="uploadDigitalAsset()">Upload New Document</button> &nbsp;
    <h1>{{$route.params.tableheading}}</h1>
    <div id="table">
      <table id="assetTable" v-if=$route.params.apiresponse>
        <thead>
          <tr>
            <th></th>
            <th>Document Name</th>
            <th>Added On</th>
            <th>TIMESTAMP</th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="assetEntry.name" v-for="assetEntry in $route.params.apiresponse">
            <td><input type="radio" id="assets" v-model="picked" v-on:change="clearError" name="picked" :value=assetEntry.name>
            <label for="assets"></label></td>
            <td>{{assetEntry.name}}</td>
            <td>{{prettyPrintDate(assetEntry.createddate)}}</td>
            <td>{{certStatus(assetEntry.otsStatus, assetEntry.timestamp)}}</td>
          </tr>
        </tbody>
      </table>
      <br>
      <span v-if=picked>You have selected <b>{{picked}}</b><br><br></span>
      <span v-if=errorMsg>{{errorMsg}}<br><br></span>
    </div>
    <button v-on:click="downloadFileForUserBoatFolder(picked)">Download Document</button> &nbsp;
    <br><br>
    <div style="width: 70%; margin: auto;">
      <span>If you experience an uploaded document that shows "Timestamp Promised" 
        after more than 48 hours, please contact Trusted Boat support 
        at "support@TrustedBoat.com"
      </span>
    </div>
    <v-dialog/>
    <vue-instant-loading-spinner id="loader" ref="Spinner"></vue-instant-loading-spinner>
  </div>
</template>
<script>
var dateFormat = require('dateformat');
import PostsService from "@/services/apiService";
import VueInstantLoadingSpinner from "vue-instant-loading-spinner/src/components/VueInstantLoadingSpinner.vue";
export default {
  name: "response",
  props: ["emailaddress", "selectedasset", "assetrecord"],
  data() {
    return {
      input: {},
      picked: null,
      response: null,
      errorMsg: null
    };
  },
  components: {
    VueInstantLoadingSpinner
  },
  methods: {
    prettyPrintDate(timestamp){
      if(timestamp) {
        try {
          return dateFormat(new Date(timestamp), "mmm d, yyyy, h:MM:ss TT") + " GMT";
        } catch {
          let t = timestamp.replace(/-/g, "/").replace(/\..*|\+.*/, "");
          if (t[10] == ':')
            t = t.replace(/:/, " ");
          console.log(t);
          return dateFormat(new Date(t), "mmm d, yyyy, h:MM:ss TT") + " GMT";
        }
      }
      else
        return null;
    },
    certStatus(status, timestamp){
      if (status == 0) return "Pending";
      if (status == 2) return "Timestamp Promised";
      if (status == 1) {
        return this.prettyPrintDate(timestamp);
      }
      return "Unknown"
    },
    findElement(array, key) {
      for (var i=0; i < array.length; i++)
        if (array[i].name === key)
          return array[i];

      // will return undefined if not found; you could return a default instead
    },
    clearError(){
      this.errorMsg = null;
    },
    async showBoatsFolders() {
      this.response = null; 
      this.errorMsg = null;
      const apiResponse = await PostsService.queryFoldersForUserBoat(
          this.$route.params.emailaddress, 
          this.$route.params.hullId
      );
      this.$router.push({ name: 'ShowBoatsFolders', params: { 
        emailaddress: this.$route.params.emailaddress, 
        hullId: this.$route.params.hullId, 
        boatName: this.$route.params.boatName, 
        apiresponse: apiResponse.data, 
        tableheading: `${this.$route.params.boatName} - Folders`}}
      );
    },
    async downloadFileForUserBoatFolder() {
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      if (this.picked === null){
        console.error('Error - no document was selected in ShowFoldersAssets.vue.')
        this.errorMsg = "Please select a document.";
        this.$forceUpdate();
        await this.hideSpinner();
      }
      else{
        let thisEntry = this.findElement(this.$route.params.apiresponse, this.picked);
        const apiResponse = await PostsService.downloadAssetForUserBoatFolder(
          this.$route.params.emailaddress, this.$route.params.hullId, 
          this.$route.params.folderName, this.picked
        );
        this.$modal.show('dialog', {
          title: 'Document is ready for Download!',
          text: 'The document ' + thisEntry.name + ' can be downloaded from <br>' + '<a href="'+apiResponse.data.link+'">Download File</a>',
          buttons: [
            {
              title: 'Close',
              default: true
            }
          ]
        })
        this.picked = null;
        this.$forceUpdate();
        this.hideSpinner();
      }
    },
    async validateAFile() {
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      if (this.picked === null){
        console.error('Error - no document was selected in ShowFoldersAssets.vue.')
        this.errorMsg = "Please select a document to validate your file against.";
        this.$forceUpdate();
        await this.hideSpinner();
      }
      else{
        let thisEntry = this.findElement(this.$route.params.apiresponse, this.picked);
        this.$router.push({ name: 'ValidateAsset', 
          params: { 
            emailaddress: this.$route.params.emailaddress,
            hullId: this.$route.params.hullId,
            boatName:  this.$route.params.boatName, 
            folderName:  this.$route.params.folderName,
            assetName: this.picked,
            assetId: thisEntry.assetGUID
          }
        });

        this.picked = null;
        this.$forceUpdate();
        this.hideSpinner();
      }
    },
    async downloadOTSFileForAsset(assetId) {
      this.errorMsg = null;
      this.response = null;
      this.runSpinner();
      let entry = this.findElement(this.$route.params.apiresponse, this.picked);
      if (this.picked === null){
        console.error('Error - no document was selected in ShowFoldersAssets.vue.')
        this.errorMsg = "Please select a document.";
        this.$forceUpdate();
        await this.hideSpinner();
      } else if (!entry || entry.otsStatus != 1){
        this.errorMsg = "Document's Timestamp is not valid.";
        this.$forceUpdate();
        await this.hideSpinner();
      }
      else{
        const apiResponse = await PostsService.downloadOTSFileForAsset(
          this.$route.params.emailaddress, this.$route.params.hullId, 
          this.$route.params.folderName, this.picked
        );
        this.$modal.show('dialog', {
          title: 'Timestamp is ready for Download!',
          text: 'The timestamp ' + thisEntry.name + '.ots can be downloaded from <br>' + '<a href="'+apiResponse.data.link+'">Download Timestamp</a>',
          buttons: [
            {
              title: 'Close',
              default: true
            }
          ]
        })
        this.picked = null;
        this.$forceUpdate();
        this.hideSpinner();
      }
    },
    async uploadDigitalAsset() {
      await this.runSpinner();
      this.$router.push({ name: 'UploadAsset', 
        params: { 
          emailaddress: this.$route.params.emailaddress,
          hullId: this.$route.params.hullId,
          boatName:  this.$route.params.boatName, 
          folderName:  this.$route.params.folderName
        }
      });
      this.$forceUpdate();
      await this.hideSpinner();
    },
    async runSpinner() {
      this.$refs.Spinner.show();
    },
    async hideSpinner() {
      this.$refs.Spinner.hide();
    }
  },
  mounted: async function() {
    //if we reached here before logging in, redirect the user to login
    if(!this.$route.params.emailaddress){
      this.$router.push({ name: 'Home'});
    }
  }
};
</script>

<style>
#assetTable {
  font-family: 'Open Sans', sans-serif;
  width: 80%;
  border-collapse: collapse;
  border: 3px solid #44475C;
  margin: 0 auto;
}

#assetTable th {
  text-transform: uppercase;
  text-align: left;
  background: #44475C;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

#assetTable td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7D82A8;
}
#assetTable td:last-child {
  border-right: none;
}

#assetTable tbody tr:nth-child(2n) td {
  background: #D4D8F9;
}

#assetTable tbody tr:nth-child(2n+1) td {
  background: #FFFFFF;
}
</style>